import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { font } from '../consts/style';
import Fade from './Fade';
import mq from '../style/mq';

const Wrapper = styled.div`
  max-width: 100vw;
  padding: 8rem 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: ${(props) => props.compact && '0'};
  h1 {
    ${(props) => (props.compact ? font.h2 : font.h1)};
  }
  h4 {
    ${(props) => (props.compact ? font.p : font.pDisplay)};
  }
  .content {
    width: ${(props) => (props.compact ? '46rem' : '64rem')};
    ${mq.tablet`
      width: ${(props) => (props.compact ? '46rem' : '53rem')};
    `}
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    .body-wrap {
      max-width: 100%;
      ${(props) => props.wd && DesktopBodyWidth}
      ${(props) => props.wt && TabletBodyWidth}
    }
  }
`;

const DesktopBodyWidth = css`
  width: ${(props) => props.wd};
`;
const TabletBodyWidth = css`
  ${mq.tablet`
       width: ${(props) => props.wt};
  `}
`;

export default function PageHeading({ title, body, compact, wd, wt }) {
  return (
    <Fade>
      <Wrapper className="row" compact={compact} wd={wd} wt={wt}>
        <div className="content">
          <h1>{title}</h1>
          {body && (
            <div className="body-wrap">
              <h4>{body}</h4>
            </div>
          )}
        </div>
      </Wrapper>
    </Fade>
  );
}

PageHeading.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  compact: PropTypes.bool,
  wd: PropTypes.string,
  wt: PropTypes.string,
};
